.presentii-toast {
  background-color: #2ea39e;
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

.presentii-toast .toast-header {
  padding: 0.5rem 1rem;
  color: white;
  background-color: rgba(255, 255, 255, 0.05);
}

.presentii-toast .toast-header .close {
  color: white;
  text-shadow: none;
}

.presentii-toast .toast-body {
  padding: 1.5rem 1.25rem;
  color: white;
}

.comments-header {
  text-transform: uppercase;
  padding-top: 0.5rem;
  color: #2ea39e;
  font-size: 120%;
  font-weight: 800;
  text-align: center;
}

.comments-container {
  margin: 0rem 0rem 1rem;
}

.comments-item {
  color: #1b1e21;
  font-weight: 600;
  text-align: center;
}

.comments-footer-button {
  color: white;
  text-align: center;
  background-color: #2ea39e;
  padding: 0.5rem 3rem;
  margin: 1rem 1.5rem 0rem;
  font-weight: 600;
}

@media screen and (min-width: 1920px) {
  .presentii-toast .toast-header {
    padding: 1rem 1.5rem;
    font-size: 1.25rem;
  }
  .presentii-toast .toast-body {
    padding: 2rem 2.25rem;
    font-size: 1.5rem;
  }
}

.cc-input-section {
  padding-bottom: 1rem;
}

.cc-input-alert {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  width: 26rem;
}

.cc-input-small-text {
  padding-top: 1rem;
}

.cc-input-card-section {
  padding-top: 1rem;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
  .cc-input-alert {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    width: 100%;
  }
}
