html {
  font-size: 14px;
}
@media screen and (min-width: 0px) and (max-width: 992px) {
  html {
    font-size: 20px;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  color: #4f4f50;
}

.modal-backdrop--medium {
  background-color: rgba(0, 0, 0, 0.2);
}

.text-decoration-line-through {
  text-decoration: line-through;
}
