.nav.nav-tabs {
  .nav-item {
    .nav-link {
      color: #4f4f50;
      font-weight: 700;
      font-size: 0.9rem;
      letter-spacing: 1px;
      border-width: 3px;
      border-color: transparent;
      border-radius: 0;
    }
    .nav-link.active {
      background: #2ea39e;
      color: #FFFFFF;
    }
  }
}