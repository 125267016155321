.defaultLogo {
  width: 200px;
  height: 100px;
}
#AddUser {
  margin-top: 5px;
  margin-bottom: 10px;
  float: right;
}
#closeButton {
  float: right;
}
.react-tabs__tab-list {
  border-bottom-width: 3px !important;
}
.react-tabs__tab-list .react-tabs__tab {
  letter-spacing: 0.28px;
  font-weight: 700;
  font-size: 0.9rem;
  letter-spacing: 1px;
  border-width: 3px;
  border-radius: 0;
  margin-bottom: -2px;
  padding: 0.7rem 1rem 1rem;
}
.react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  background-color: #2ea39e;
  border-color: #2ea39e;
  color: white;
}

.addPaymentModal .modal-content {
  border-radius: 0;
  border: 0;
}
.addPaymentModal .modal-content:before {
  display: block;
  content: "";
  position: absolute;
  background: #2ea39e;
  width: 100%;
  height: 100%;
  top: 17px;
  left: 17px;
  z-index: -1;
}
.addPaymentModal .modal-body {
  padding: 30px 30px 15px;
}
.addPaymentModal .modal__title {
  text-align: center;
  color: #2ea39e;
}
.addPaymentModal .modal-footer {
  padding: 15px 30px 30px;
  border-top: 0;
  justify-content: center;
}
.btn-primary {
  letter-spacing: 0.28px;
  font-weight: 700;
  font-size: 0.9rem;

  letter-spacing: 1px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  min-width: 112px;
  min-height: 3.2143rem;
  border-width: 3px;
  background-color: #2ea39e;
  border-color: #2ea39e;
  border-radius: 0;
  /* display: block; */
  /* margin: 0 auto;
    margin-top: 20px; */
}
