#afterClickArchives {
  z-index: 99;
  float: right;
}
#RecordDiv {
  display: inline-block;
}
.mediaRecorder {
  visibility: hidden;
}
