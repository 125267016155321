/* CONTAINER */
.product-cards {
  margin-bottom: 50px;
  margin-top: 30px;
}

/* STRUCTURE */
.product-cards .card  {
  padding-top: 50px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.product-cards .card .card-body *, .product-cards tr {
  font-weight: 700;
  color: #4f4f50;
  letter-spacing: 0.1rem;
}
.product-cards .plan-engage {
  background: #EBF6F5;
  --accent: #33A39E;
}
.product-cards .plan-professional {
  background: #EDEDEE;
  --accent: #4f4f50;
}
.product-cards .plan-enterprise {
  background: #FCEFED;
  --accent: #E2624E;
}
.product-cards .plan-subscribed {
  background: white;
  outline: solid 1px #EDEDEE;
  --accent: #b9b9b9;
  min-height: 550px;
}
.product-cards .card-body {
  padding: 0 !important;
}

/* COPY */
.product-cards .card .card-body h3 {
  color: var(--accent);
  font-weight: 900;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding: 0 10px;
}

.product-cards .card .card-subtitle > p {
  font-weight: 900;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 30px;
  min-width: 50px;
  position: relative;
}
.product-cards .card .card-subtitle > p::first-letter {
  /* the $ in $12 */
  font-size: 1.2rem;
  vertical-align: super;
}
.product-cards .card .card-subtitle > p span {
  position: absolute;
  font-size: 0.8rem;
  font-weight: 900;
  bottom: -2px;
  right: -30px;
}

/* .product-cards .card:not(.subscription-card) .card-body .card-subtitle {
  padding: 30px;
} */
.product-cards .card .user-pick {
  padding-bottom: 30px;
}
.product-cards .card .user-pick * {
  display: inline-block;
  color: #666;
}
.product-cards .card .user-pick p {
  margin: 0 5px;
  min-width: 75px;
}
.product-cards .card .user-pick button {
  background: transparent;
  border: solid 1px var(--accent);
  border-radius: 100%;
  font-size: 0.75rem;
  height: 20px;
  width: 20px;
}
.product-cards .card .user-pick button:not([disabled]):focus,
.product-cards .card .user-pick button:not([disabled]):hover {
  background: var(--accent);
  border-color: var(--accent);
  outline: var(--accent);
}
.product-cards .card .user-pick button:focus path,
.product-cards .card .user-pick button:hover path {
  fill: white;
}
.product-cards .card .user-pick button[disabled],
.product-cards .card .user-pick button[disabled]:focus,
.product-cards .card .user-pick button[disabled] path {
  border-color: #999;
  outline: #999;
  fill: #999;
}
.product-cards .card .user-pick button svg {
  height: 10px;
  width: 10px;
}

.product-cards .subscription-card .card-subtitle {
  padding-bottom: 40px;
}
.product-cards .subscription-card .card-subtitle p {
  display: inline-block;
  position: relative;
}
.product-cards .subscription-card .card-subtitle span {
  font-size: 1rem;
  position: absolute;
  bottom: -1rem;
  right: -1rem;
}

/* TABLE */
.product-cards .card .table {
  margin: 30px 0;
  position: relative;
}
.product-cards .card .table::before {
  content: '';
  display: block;
  width: 75%;
  height: 2px;
  background: var(--accent);
  position: absolute;
  left: 12.5%;
  top: -30px;
}
.product-cards .card .table-striped tr {
  background: none;
}
.product-cards .card .table-striped tr:nth-of-type(2n) {
  background-color: #E0E0E0;
}
.product-cards .subscription-card .table-striped tr:nth-of-type(2n) {
  background-color: #F1EBE5;
}
.product-cards .card .table-striped td {
  font-weight: 900;
  border: 0;
  letter-spacing: 0.1rem;
  padding: 10px 20px;
}

/* BUTTON */
.product-cards .card:not(.subscription-card) .card-body .btn {
  line-height: 50px;
  font-size: 17px;
  width: 75%;
  margin-bottom: 20px;
}
.product-cards .card .card-body .btn {
  background: var(--accent);
  color: #fff;
  margin:  0 auto;
  border: 2px solid var(--accent);
}
.product-cards .subscription-card .card-body .btn {
  width: 50%;
}
.product-cards .card .card-body .btn:hover,
.product-cards .card .card-body .btn:focus {
  background: #fff;
  color: var(--accent);
}
.product-cards .card .card-body .nav-link:not(.btn) {
  text-decoration: underline;
  padding: 0;
}
.product-cards .card .card-body .nav-link:not(.btn):hover {
  color: var(--accent)
}

@media only screen and (min-width: 768px) and (max-width: 1338px){
  /* STRUCTURE */
  .product-cards .card  {
    padding-bottom: 30px;
  }
  /* TABLE */
  .product-cards .card .table {
    margin: 30px 0 50px;
    position: relative;
  }
  .product-cards .card .table::before {
    width: 100%;
    left: 0;
  }
  .product-cards .card .table td {
    font-size: 0.9rem;
  }
  /* BUTTON */
  .product-cards .card .card-body .btn {
    width: 100% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px){
  /* STRUCTURE */
  .product-cards > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .product-cards >*:nth-child(3) .card {
    margin-top: 30px;
  }
  /* COPY */
  .product-cards .card .card-body h3 {
    font-size: 25px;
  }
  .product-cards .card .card-body .card-subtitle {
    height: 145px;
  }
  /* TABLE */
  .product-cards .card .table {
    margin-bottom: 20px;
  }
  .product-cards .card .table td {
    font-size: 0.6rem;
    font-weight: 600;
  }
  /* BUTTON */
  .product-cards .card .card-body .btn {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  /* COPY */
  .product-cards .card .card-body .card-subtitle {
    height: 100%;
  }
}

